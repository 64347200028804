import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Carousel from '../../Components/SmallCarousel';
import {BREAKFAST_MOCK, AFTERNOON_MOCK} from '../../MockData';
import withPageEntry from '../../withPageEntry';

function Foods(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');

  const {showBanners = true} = props;
  const [bannerDimension, setBannerDimension] = React.useState(null);

  let breakfastImgWidth =
    dimension.rwd === 'desktop'
      ? (dimension.innerWidth - 200 - 20) / 4
      : (dimension.innerWidth - 40) / 2;

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  return (
    <Wrapper dimension={dimension} id="rev-FoodPage">
      {showBanners && (
        <div
          id="rev-banner"
          className="banner"
          style={{height: bannerDimension?.width * 0.38}}>
          <img
            alt="food"
            src="../../images/food-banner-1.png"
            style={{width: '100%', height: '100%', objectFit: 'cover'}}
          />
        </div>
      )}

      <section className="section-dark">
        <div className="subtitle">NOURISHMENT DIET</div>
        <div className="title">主題月子餐</div>
        <p
          className="content"
          style={{
            marginTop: 40,
          }}>{`青田悅的廚房團隊夥伴來自於不同的菜系領域，包含中廚、廣式、西餐、日式料理、甜點…等，他們用自己專業，不斷激盪研發出讓媽咪能吃的健康又營養均衡的各種菜單
在坐月子期間媽咪能吃到的食物限制會比較多，青田悅主打菜色豐富具變化性，讓媽咪不會只吃到中式月子餐，四周的菜單裡融入了日式、泰式、義式、客家料理甚至原住民餐的主題料理。
為了吃的安心健康，青田悅的餐點使用大量的原型食物，媽媽最需要的高湯，除了藥膳類以藥材作為湯底外，其他的湯品更是無添加任何的化學調味劑，全用蔬菜水果熬煮出湯品的甜味，讓媽咪身體沒有負擔!
`}</p>
        <div className="pj-row wrap" style={{marginTop: 40}}>
          {BREAKFAST_MOCK.map((img, idx) => (
            <img
              alt={`breakfast-${idx}`}
              src={img}
              style={{
                width: breakfastImgWidth,
                height: breakfastImgWidth,
                objectFit: 'cover',
              }}
            />
          ))}
        </div>
      </section>
      <section className="section">
        <div className="subtitle">ME TIME</div>
        <div className="title">下午茶時光</div>
        <div className="carousel-wrapper">
          <div style={{flex: 1}}>
            <Carousel
              width={
                dimension.rwd === 'desktop'
                  ? dimension?.innerWidth / 2
                  : dimension?.innerWidth - 40
              }
              height={
                dimension.rwd === 'desktop'
                  ? (dimension.innerWidth / 2) * 0.66
                  : dimension.innerWidth * 0.66
              }
              items={AFTERNOON_MOCK}
              // style={{position: 'absolute', top: 0, left: 0}}
            />
          </div>

          <div style={{flexBasis: 40}} />
          <div style={{flex: 1}}>
            {/* <div className="title">屬於爸爸媽媽的 下午茶時光</div> */}
            <p className="content">
              {`「有了寶寶以後,世界裡就都是他/她了」
寶寶的一個哭一個笑都牽動著我們的心
我們知道有了寶寶以後
想要享受一個人的時間有多不容易
照顧寶寶之餘
也別忘了先照顧自己
讓青田悅好好照顧你
享受自己專屬的時刻吧
`}
            </p>
          </div>
        </div>
      </section>
      <section className="section-dark">
        <div className="subtitle">BREAKFAST</div>
        <div className="title">陪宿者早餐</div>
        <p
          className="content"
          style={{marginTop: 40}}>{`「因為曾經經歷過，所以能理解；
因為理解，所以更想為您做點什麼。」
這是由暖心爸爸創辦的青田悅之初衷
除了媽媽與寶寶
總會適時提醒我們也要以爸爸為出發點思考
其中一項就是青田悅獨創的＜陪宿者早餐＞
考量陪宿者需要在月子中心與公司間奔波
還需在途中找到方便停車的地方買早餐
青田悅每天早上都會為爸爸提供早餐
`}</p>
      </section>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="food-banner-2"
          src="../../images/food-banner-2.png"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: var(--topNavBarHeight);

  & > .banner {
    width: 100%;
    height: 600px;
  }

`;

export default withPageEntry(Foods);
