let ROOM_MOCK = [
  {
    name: '青雲房',
    engName: 'Joy Suite',
    info: [
      {
        title: '',
        content: `全館唯一頂樓套房，擁有室內12坪(一室一廳一衛)與專屬12坪空中花園。享有智慧車牌辨識系統的室內停車位。\n房內即有獨立式冷暖空調、廚下型飲水器、星球電鍋、2台55吋液晶電視及獨享浴缸。\n入住就享有智慧車牌辨識系統的室內停車位。\n\n①2張單人德國馬達靜音電動床\n②全館德國全戶BWT淨水系統\n③乾溼分離衛浴設備，搭配冷暖風機、免治馬桶、12加侖儲熱型熱水器。\n\n體貼媽媽暖心設備： Combi奶消鍋、貝親溫奶器、貝瑞克吸乳器、個人儲奶雙門冰箱、隱私拉簾 `,
      },
    ],
    imagesArray: [
      '../../images/青雲房1.jpg',
      '../../images/青雲房2.jpg',
      '../../images/青雲房3.jpg',
      '../../images/青雲房4.jpg',
      '../../images/青雲房5.jpg',
    ],
  },
  {
    name: '青海房',
    engName: 'Bliss Suite',
    info: [
      {
        content: `友善大寶的最佳房型，10坪空間讓大寶享有專屬的床邊床或氣墊床，更保留大寶自由奔跑的空間。
戶戶享有專屬小陽台並搭配落地窗，入住便即刻享有車牌辨識室內停車位。`,
      },
      {
        title: '媽媽產後快速修復鐵三角',
        content: `①2張單人德國馬達靜音電動床。\n②全館德國全戶BWT淨水系統\n③乾溼分離衛浴設備，搭配冷暖風機、免治馬桶、12加侖儲熱型熱水器。\n\n體貼媽媽暖心設備： Combi奶消鍋、貝親溫奶器、貝瑞克吸乳器、個人儲奶雙門冰箱、隱私拉簾`,
      },
    ],
    imagesArray: [
      '../../images/青海房1.jpg',
      '../../images/青海房2.jpg',
      '../../images/青海房3.jpg',
    ],
  },
  {
    name: '青田房',
    engName: 'Discover Suite',
    info: [
      {
        content: `青田悅的經典房型，在8坪的簡約風格中優雅的坐月子。戶戶享有專屬小陽台並搭配落地窗。`,
      },
      {
        title: '媽媽產後快速修復鐵三角',
        content: `①2張單人德國馬達靜音電動床\n②全館德國全戶BWT淨水系統\n③乾溼分離衛浴設備，搭配冷暖風機、免治馬桶、12加侖儲熱型熱水器\n\n體貼媽媽暖心設備： Combi奶消鍋、貝瑞克吸乳器、個人儲奶雙門冰箱、隱私拉簾`,
      },
    ],
    imagesArray: [
      '../../images/青田房1.jpg',
      '../../images/青田房2.jpg',
      '../../images/青田房3.jpg',
    ],
  },
  {
    name: '青山房',
    engName: 'Happy Suite',
    info: [
      {
        content: `6坪的預備房，不必擔心預產期的不確定性，讓人安心生產優雅入住。麻雀雖小五臟俱全的空間裡也享有專屬小陽台並搭配落地窗。`,
      },
      {
        title: '媽媽產後快速修復鐵三角',
        content: `①2張單人德國馬達靜音電動床\n②全館德國全戶BWT淨水系統\n③乾溼分離衛浴設備，搭配冷暖風機、免治馬桶、12加侖儲熱型熱水器\n\n體貼媽媽暖心設備： Combi奶消鍋、貝瑞克吸乳器、個人儲奶雙門冰箱`,
      },
    ],
    imagesArray: [
      '../../images/青山房1.jpg',
      '../../images/青山房2.jpg',
      '../../images/青山房3.jpg',
    ],
  },
];

let SERVICE_MOCK = [
  {
    name: '舒壓芳療',
    info: [
      {
        title: '紓壓芳療服務',
        content:
          '大片落地窗的寂靜綠意空間，空氣中瀰漫著清新天然氣息，盡情享受一對一芳療減壓課程，讓您悅住越美麗。',
      },
    ],
    imagesArray: ['../../images/photo-1.jpg'],
  },
  {
    name: '中醫師不藏私',
    info: [
      {
        title: '紓壓芳療服務',
        content:
          '華人特別重視坐月子期間的調理，每周一次駐診，每月固定講座，針對產後不同階段從飲食切入，教媽媽如何食補，如何維持良好新陳代謝，讓身體在關鍵的這一個月能好好重整恢復健康。',
      },
    ],
    imagesArray: ['../../images/photo-2.jpg'],
  },
  {
    name: '產後修復瑜珈',
    info: [
      {
        title: '紓壓芳療服務',
        content:
          '舒服輕鬆的產後修復瑜伽練習中，能舒緩肩頸痠痛、媽媽手，同時喚醒骨盆底肌群，利用練習凱格爾運動改善產後不適症狀。緩解月子期間因為久坐、久躺造成的下肢循環不良，釋放腿部壓力。呼吸練習—幫助改善呼吸品質，喚回平靜的心，讓媽咪在照表操課的日常中，也能有好好喘息的美好時光。',
      },
    ],
    imagesArray: ['../../images/photo-3.jpg'],
  },
  {
    name: '寶寶攝影',
    info: [
      {
        title: '紓壓芳療服務',
        content:
          '用愛安撫，用心留影，為30天內初生之嬰兒所設，將最珍貴的紀錄送給您，為寶寶留下最純真的時刻。',
      },
    ],
    imagesArray: ['../../images/photo-4.jpg'],
  },
];

let SERVICE_CAROUSAL_MOCK = [
  '../../images/service-1.jpg',
  '../../images/service-2.jpg',
];

let BREAKFAST_MOCK = [
  '../../images/food-1.jpg',
  '../../images/food-2.jpg',
  '../../images/food-3.jpg',
  '../../images/food-4.jpg',
];

let AFTERNOON_MOCK = ['../../images/food-5.jpg'];

export {
  ROOM_MOCK,
  SERVICE_MOCK,
  BREAKFAST_MOCK,
  AFTERNOON_MOCK,
  SERVICE_CAROUSAL_MOCK,
};