import React from 'react';
import Carousel from './Carousel';
import styled from 'styled-components';

function CutCarousel(props) {
  const [currItem, setCurrItem] = React.useState(null);
  const {width, height, items, rwd, style = {}} = props;

  return (
    <Wrapper style={style} rwd={rwd}>
      <Carousel
        width={width}
        height={height}
        data={items}
        renderPrev={null}
        renderNext={null}
        renderDots={({currIdx, goToIndex}) => {
          const item = items[currIdx];
          setCurrItem(item);
          return (
            <FlexRow rwd={rwd}>
              {/* <h1>{item.title}</h1> */}
              <div className="btns-wrapper">
                <Arrow
                  onClick={() => goToIndex(currIdx - 1)}
                  style={{
                    width: 40,
                    height: 40,
                    borderLeft: 'none',
                    userSelect: 'none',
                  }}>
                  <div className="arrow-prev" />
                </Arrow>
                <Arrow
                  onClick={() =>
                    goToIndex(currIdx === items.length - 1 ? 0 : currIdx + 1)
                  }
                  style={{
                    width: 40,
                    height: 40,
                    userSelect: 'none',
                    marginLeft: 20,
                  }}>
                  <div className="arrow-next" />
                </Arrow>
              </div>
            </FlexRow>
          );
        }}
        renderItem={({item}) => (
          <div style={{width: '100%', height: '100%', position: 'relative'}}>
            <img
              src={item}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 20,
              }}
              alt="product"
            />
          </div>
        )}
        disableSwipe
      />
      {/* {currItem && (
        <div className="outside-title">
          <h1>{currItem.title}</h1>
          <p>
            {currItem.label} |{' '}
            {currItem.created &&
              currItem.created.replace('T', ' ').slice(0, 16)}
          </p>
        </div>
      )} */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .outside-title {
    max-width: 320px;
    margin: 35px 40px 0px 0px;
    & > h1 {
      margin-bottom: 15px;
      font-size: ${(props) => (props.rwd === 'mobile' ? '24px' : '30px')};
      font-weight: normal;
      line-height: 40px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  & > h1 {
    min-width: 60%;
    min-height: 20%;
    padding: ${(props) => (props.rwd === 'desktop' ? '15px 20px' : '5px 20px')};
    position: absolute;
    left: 30px;
    bottom: 20px;
    font-weight: bold;
    line-height: 1.46;
    color: white;
    font-size: ${(props) => {
      if (props.rwd === 'desktop') {
        return '28px';
      } else if (props.rwd === 'pad') {
        return '21px';
      } else {
        return '16px';
      }
    }};
  }
  & .padding {
    flex: 1;
  }
  & .btns-wrapper {
    position: absolute;
    display: flex;
    bottom: 20px;
    right: 30px;
  }
  ${(props) => props.css}
`;

const Arrow = styled.div`
  z-index: 1;
  position: relative;
  width: 120px;
  height: 20px;
  cursor: pointer;
  & > .arrow-next {
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../images/arrow.png');
  }

  & > .arrow-prev {
    background-size: contain;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../images/arrow-reverse.png');
  }
`;

export default CutCarousel;